var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Welcome to "),_c('strong',[_vm._v("AMP")]),_vm._v(" 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Register below to join our global network of marketers that earn passively by marketing our products. ")]),_c('validation-observer',{ref:"registerValidation"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"firstname"}},[_vm._v("Firstname")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","state":errors.length > 0 ? false:null,"name":"firstname","placeholder":"John"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"lastname"}},[_vm._v("Lastname")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 0 ? false:null,"name":"lastname","placeholder":"Doe"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"name":"register-username","placeholder":"johndoe"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("Confirm Password")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"confirm password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirm-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"confirm-password","placeholder":"············"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I have read and understood the "),_c('b-link',{attrs:{"to":"/privacy-policy"}},[_vm._v(" Privacy Policy ")])],1)],1),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":""},on:{"click":_vm.register}},[_vm._v(" Sign Up & Start Earning ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v("Sign in instead")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }